import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {Link} from "react-router-dom";


const Widget = ({ type, value }) => {
    let data = {};
  switch (type) {
      case "employees":
      data = {
        title: "EMPLOYEES",
        link: "employye-list",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "hired_this_year":
      data = {
        title: "NEW EMPLOYEES",
        link: "",
        icon: (
          <KeyboardArrowUpIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
      case "resigned_this_year":
      data = {
        title: "RESIGNED",
        link: "resigned",
        icon: (
          <KeyboardArrowDownIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }
    data["result"] = value;
  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
            {data.result}
        </span>
        <span className="link">
            <Link to={data.link}/>
        </span>
      </div>
      <div className="right">
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;