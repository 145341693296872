import { API_URLS } from "./URLs";
import { WebApi } from "../helpers/webApi.helper";

const api = new WebApi(API_URLS.BASE);
export const API = {
  AUTH: {
    login: (access_token) =>
      api.post(API_URLS.AUTH.LOGIN, { token: access_token }),
    logout: () => api.post(API_URLS.AUTH.LOGOUT),
  },
  CURRENT_USER: {
    get: () => api.get(API_URLS.CURRENT_USER.GET)
  },
  EMPLOYEES: {
      list: () => api.get(API_URLS.EMPLOYEES.BASE),
      stats: {
          base:{
         list: () => api.get(API_URLS.EMPLOYEES.STATS.BASE)
            },
        age: {
          list: () => api.get(API_URLS.EMPLOYEES.STATS.AGE)
            },
      duration: {
          base: {
              list: () => api.get(API_URLS.EMPLOYEES.STATS.DURATION.BASE)
          },
          stats: {
              list: () => api.get(API_URLS.EMPLOYEES.STATS.DURATION.STATS)
          }
          },
      grades:{
          list: () => api.get(API_URLS.EMPLOYEES.STATS.GRADES)
      },
      seasons:{
          list: () => api.get(API_URLS.EMPLOYEES.STATS.SEASONS)
      },
      },
      active: {
       list: () => api.get(API_URLS.EMPLOYEES.ACTIVE)
        },
      projects: {
       list: () => api.get(API_URLS.EMPLOYEES.PROJECTS)
        },
      hrmeetings: {
          list: () => api.get(API_URLS.EMPLOYEES.HRMEETINGS.GET)
      },
      qmeetings: {
          list: () => api.get(API_URLS.EMPLOYEES.QMEETINGS.GET)
      }
  },
  SALARIES: {
      list: () => api.get(API_URLS.SALARIES.BASE),
      AVERAGE: {
          all: () => api.get(API_URLS.SALARIES.AVERAGE.ALL),
          departments: () => api.get(API_URLS.SALARIES.AVERAGE.DEPARTMENTS),
          projects: () => api.get(API_URLS.SALARIES.AVERAGE.PROJECTS)
      }
  },
  GRADES:{
      list: () => api.get(API_URLS.EMPLOYEES.STATS.GRADES)
  },
  DEPARTMENTS: {
      list: () => api.get(API_URLS.DEPARTMENTS)
  },
  CONTRACTORS: {
      list: () => api.get(API_URLS.CONTRACTORS)
  },
  OFFICES: {
      list: () => api.get(API_URLS.OFFICES)
  },
  COUNTRIES: {
      list: () => api.get(API_URLS.COUNTRIES)
  },
  RESIGNED: {
      list: () => api.get(API_URLS.RESIGNED.BASE),
      projects: {
       list: () => api.get(API_URLS.RESIGNED.PROJECTS)
        },
      duration: {
          base: {
              list: () => api.get(API_URLS.RESIGNED.DURATION.BASE)
            },
          stats:{
              list: () => api.get(API_URLS.RESIGNED.DURATION.STATS)
          }
      },
      seasons: {
          list: () => api.get(API_URLS.RESIGNED.SEASONS)
      },
      reasons: {
          list: () => api.get(API_URLS.RESIGNED.REASONS)
      }
  }
};
