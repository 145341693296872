import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

function processValues(values) {
  const threshold = 0.05; // 5% threshold
  const total = values.reduce((acc, val) => acc + val.value, 0);
  const groupedValues = [];
  let othersValue = 0;

  values.forEach((item) => {
    const percentage = item.value / total;
    if (percentage > threshold) {
      groupedValues.push(item);
    } else {
      othersValue += item.value;
    }
  });

  if (othersValue > 0) {
    groupedValues.push({ name: 'Others', value: othersValue });
  }

  return groupedValues;
}

export default function Pie({ values }) {
  const processedValues = processValues(values);
  return (
    <PieChart
      series={[
        {
          data: processedValues,
          label: {
            show: true,
            position: 'inside',
            formatter: '{b}: {d}%',
          },
        },
      ]}
      width={500}
      height={200}
      sx={{
        [`& ..css-1u0lry5-MuiChartsLegend-root `]: {
          color : 'gray',
          fontWeight: 'bold',
        },
      }}
    />
  );
}