import "./home.scss";
import Widget from "../../components/widgets/Widget";
import {useEffect} from "react";
import {useFetchData} from "../../contexts/DataContext";
import { API } from "../../api/api";
import Pie from "../../components/charts/Pie";
import Loader from "../../components/loader/Loader";


const Home = () => {
    const {
        fetchData: getStats,
        data: stats,
    } = useFetchData(API.EMPLOYEES.stats.base.list, []);

    useEffect(() => {
        getStats();
     }, []);

    const activeEmployees = stats.active_employees_count;
    const newHiresThisYear = stats.new_employees_count;

    const resignedThisYear = stats.resigned_employees_count;
    const softwareEmployees = stats.software_department_employee_count;
    const otherEmployees = activeEmployees - softwareEmployees;
    const pieData = [
        { value: softwareEmployees, label: "Software Developers"},
        { value: otherEmployees, label: "Other Employyes"}
    ]
    return (

    <div className="home">
      <div className="homeContainer">
          <Loader data={stats.length}>
          {(stats.length !== 0) ? (<>
                  <div className="widgets">
                      <Widget type="employees" value={activeEmployees}/>
                      <Widget type="hired_this_year" value={newHiresThisYear}/>
                      <Widget type="resigned_this_year" value={resignedThisYear}/>
                  </div>
                  <div className="chartsContainer">
                      <div className="chart">
                  <div className="chart-title">Software developers compared to all employees</div>
                  <div className="charts">

                      <Pie values={pieData}/>
                  </div>
                          </div>
                  </div>
              </>) :
              (<div>Waiting for loading</div>
              )}
          </Loader>
      </div>
    </div>
  );
};

export default Home;