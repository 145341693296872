import { getCsrfToken } from "./csrfToken.helper";
import { getAccessToken } from "./accessToken.helper";

export class WebApi {
  baseUrl = "";

  constructor(url = "") {
    this.baseUrl = url;
  }

  get headers() {
    const accessToken = getAccessToken();
    const csrfToken = getCsrfToken();
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": window.location.origin,
      "Client-Location": Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (accessToken) headers["Authorization"] = `Bearer ${accessToken}`;
    if (csrfToken) headers["X-CSRFToken"] = csrfToken;

    return headers;
  }

  get = async (url, params) => this.request(url, "GET", { params });

  post = async (url, data) => this.request(url, "POST", { data });

  patch = async (url, data) => this.request(url, "PATCH", { data });

  request = async (url, method, { params, data }) =>
    new Promise(async (resolve, reject) => {
      const response = await fetch(
        `${this.baseUrl}${url}` +
          (params ? "?" + new URLSearchParams(params) : ""),
        {
          method,
          headers: this.headers,
          body: data ? JSON.stringify(data) : null,
        }
      );
      const result = await response.json()
          .then(res=>res)
          .catch((err)=>err);
      response.ok ? resolve({ data: result }) : reject({ data: result });
    });
}
