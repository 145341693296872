import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import GroupsIcon from '@mui/icons-material/Groups';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { useAuth } from "../../contexts/AuthContext";
import './sidebar.scss';
const NavItems = [
    {
        display: '',
        icon: <DashboardIcon/>,
        to: '/',
    },
    {
        display: '',
        icon: <PersonOutlineIcon/>,
        to: '/employees'
    },
    {
        display: '',
        icon: <PersonOffIcon/>,
        to: "/resigned",
    },
    {
        display: '',
        icon: <ContentPasteGoIcon/>,
        to: "/contractors",
    },
    {
        display: '',
        icon: <CreditCardIcon/>,
        to: "/salaries",
    },
    {
        display: '',
        icon: <GroupsIcon/>,
        to: "/groups",
    },
    {
        display: '',
        icon: <AnalyticsIcon/>,
        to: '/analytics',
    },
    {
        display: '',
        icon: <MeetingRoomIcon/>,
        to: "/meetings",
    },

];

const Sidebar = () => {
    let sidebarNavItems = [];
    const [activeIndex, setActiveIndex] = useState(0);
    const [indicatorTop, setIndicatorTop] = useState(0);
    const [indicatorHeight, setIndicatorHeight] = useState(0);
    const sidebarRef = useRef();
    const indicatorRef = useRef();
    const location = useLocation();
    const { user } = useAuth();
    const isAdmin = user.groups.some(group => group.name === 'Admin');
    if (!isAdmin){
        sidebarNavItems = NavItems.filter(item => item.to !== "/salaries")
    }
    else{
        sidebarNavItems = NavItems;
    }
    const isHR = user.groups.some(group => group.name === 'HR' ) || isAdmin;
    if (!isHR){
        sidebarNavItems = NavItems.filter(item =>(item.to !== "/meetings" &&
            item.to !== "/analytics"&& item.to !== "/groups" && item.to !== "/salaries"))
    }

    useEffect(() => {
        const sidebarItem = sidebarRef.current.querySelector('.sidebar__menu__item.active');
        if (sidebarItem) {
            setIndicatorTop(sidebarItem.offsetTop);
            setIndicatorHeight(sidebarItem.clientHeight);
        }
    }, [activeIndex]);

    useEffect(() => {
        const curPath = window.location.pathname.split('/')[1];
        const activeItem = sidebarNavItems.findIndex(item => item.to === `/${curPath}`);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    return (
        <div className='sidebar'>

            <div ref={sidebarRef} className="sidebar__menu">
                    <div
                        ref={indicatorRef}
                        className="sidebar__menu__indicator"
                        style={{
                            top: indicatorTop,
                            height: indicatorHeight,
                        }}
                    ></div>
                    {sidebarNavItems.map((item, index) => (
                        <Link to={item.to} key={index}>
                            <div className={`sidebar__menu__item ${activeIndex === index ? 'active' : ''}`}>
                                <div className="sidebar__menu__item__icon">
                                    {item.icon}
                                </div>
                                <div className="sidebar__menu__item__text">
                                    {item.display}
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
        </div>
);
};

export default Sidebar;
