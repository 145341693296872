import Home from "./pages/Home/Home";
import Employees from "./pages/Employee/Employees";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from './components/layout/AppLayout';
import Analytics from "./pages/Analytics/Analytics";
import Salaries from "./pages/Salaries/Salaries";
import Resigned from "./pages/Resigned/Resigned";
import EmployeeGroups from "./pages/EmployeeGroups/EmployeeGroups";
import { AuthProvider } from "./contexts/AuthContext";
import Meetings from "./pages/Meetings/Meetings";
import Contractors from "./pages/Contractors/Contractors";
function App() {

  return (

      <BrowserRouter>
          <AuthProvider>
        <Routes>
          <Route path='/' element={<AppLayout />}>
            <Route index element={<Home />} />
              <Route path='/employees' element={<Employees />}/>
              <Route path='/analytics' element={<Analytics/>}/>
              <Route path='/salaries' element={<Salaries/>}/>
              <Route path='/resigned' element={<Resigned/>}/>
              <Route path='/groups' element={<EmployeeGroups/>}/>
              <Route path='/meetings' element={<Meetings/>}/>
              <Route path='/contractors' element={<Contractors/>}/>
              <Route path='*' element={<Home/>} />
          </Route>
        </Routes>
      </AuthProvider>
      </BrowserRouter>
  );
}

export default App;