export const API_URLS = {
  BASE: "/api",

  AUTH: {
    LOGIN: "/google/",
    LOGOUT: "/logout/",
  },

  CURRENT_USER: {
    GET: "/user/",
    UPDATE: "/user/",
  },

  EMPLOYEES: {
    BASE: "/employees",
    ACTIVE: "/employees?is_active=True",
    STATS: {
      BASE: "/employees/stats",
      AGE: "/employees/age",
      DURATION: {
        BASE: "/employees/duration",
        STATS: "/employees/duration/stats",
      },
      SEASONS: "/employees/seasons/stats",
      GRADES: "/employees/grades"
    },
    PROJECTS: "/employees/projects?is_active=True",
    HRMEETINGS: {
      GET: "/employees/meetings/hr"
    },
    QMEETINGS: {
      GET: "/employees/meetings/quantum"
    },
  },

    SALARIES: {
      BASE: "/salaries",
      AVERAGE: {
        ALL: "/salaries/average",
        DEPARTMENTS: "/salaries/departments/average",
        PROJECTS: "/salaries/projects/average"
      }
    },
    DEPARTMENTS: "/departments",
    CONTRACTORS: "/contractors",
    RESIGNED: {
      BASE: "/resigned",
      PROJECTS: "/employees/projects?is_active=False",
      DURATION: {
        BASE: "/resigned/duration",
        STATS: "/resigned/duration/stats"
      },
      REASONS: "/resigned/reasons/stats",
      SEASONS: "/resigned/seasons/stats"
    },
    COUNTRIES: "/countries",
    OFFICES: "/offices"
};
