import Datatable from "../../components/datatable/Datatable";
import {useEffect, } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { API } from "../../api/api";
import { useFetchData } from "../../contexts/DataContext";
import Loader from "../../components/loader/Loader";
const Employees = () => {
  const { user } = useAuth();
  const {
    fetchData: getEmployees,
    data: employees,
  } = useFetchData(API.EMPLOYEES.active.list, []);

  useEffect(() => {
    getEmployees();
  }, []);

  return (
      <Loader data={employees.length}>
    <div className="home">
      <div className="homeContainer">
        <div className="tables">
        {employees.length ? (<Datatable data={employees} link={"/humanresourses/employee"} title={"Employee list"}/>):
            (<div>Waiting for loafing</div>
            )}
        </div>
      </div>
    </div>
      </Loader>
  );
};

export default Employees;