import clsx from 'clsx';
export const userColumns = [
    {field: "name", headerName:"Name", width: 150},
    {field: "surname", headerName:"Surname", width: 150},
    {field: "email", headerName:"E-mail", width: 280},
    {field: "mobile", headerName:"Phone Number", width: 150},
    {field: "born_date", headerName:"Born Date", width: 120},
    {field: "sex", headerName:"Sex", width: 20},
    {field: "grade", headerName: "Grade", width: 100},
    {field: "year", headerName: "Year", width: 100},
    {field: "department", headerName:"Department", width: 120},
    {field: "lead_date", headerName:"Lead Date", width: 120},
    {field: "end_date", headerName: "End Date", width: 120},
    {field: "projects", headerName:"Projects", width: 300},
    {field: "age", headerName: "Age", width: 50},
    {field: "merch_size", headerName: "Merch", width:60},
    {field: "month", headerName: "Month", width: 100},
    {field: "office", headerName:"Office", width: 100},
    {field: "last_date", headerName:"Last Date", width: 120},
    {field: "location_country", headerName:"Country", width: 100},
    {field: "salary", headerName:"Salary", width: 80},
    {field: "reason", headerName: "Reason", width: 150},
    {field: "ratio", headerName: "Ratio", width: 100},
    {field: "rate", headerName: "Rate", width: 80},
    {field: "market_salary", headerName: "Market Salary", width: 150},
    {field: "next_date_range", headerName: "Next Date Range", width: 200},
    {field: "days_until", headerName: "Days Left", width: 100, cellClassName: (params) => {
      if (params.value == null) {
        return '';
      }
      return clsx('colors', {
        red: params.value < 15,
        yellow: params.value >= 15 && params.value < 45,
        green: params.value >= 45
      })
    },
    valueFormatter: (value) => `${value.value}  days`
}
]

export const filterDataByFields = (data, keys) => {
    return data.filter(item => keys.includes(item.field));
};