import Datatable from "../../components/datatable/Datatable";
import {useEffect, } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { API } from "../../api/api";
import { useFetchData } from "../../contexts/DataContext";

import Loader from "../../components/loader/Loader";

const Resigned = () => {
    const {
    fetchData: getEmployees,
    data: employees,
  } = useFetchData(API.RESIGNED.projects.list, []);

  useEffect(() => {
    getEmployees();
  }, []);

const {
        fetchData: getReasons,
        data: resigneded,
    } = useFetchData(API.RESIGNED.list, []);

    useEffect(() => {
        getReasons();
    }, []);
const { user } = useAuth();
  const isAdmin = user.groups.some((group) => (group.name === 'Admin' || group.name === "HR"));

const dataWithReasons = employees.map(employee => {
  const resigned = resigneded.find(resigned => resigned.employee === employee.employee_id);
  return {
    id: employee.id,
    name: employee.name,
    surname: employee.surname,
    projects: employee.projects.map((project) => {
        return project.is_main ? `${project.name} (Main)` : `${project.name}`;}).join(", "),
    department: employee.department,
    lead_date: resigned.lead_date,
    reason: (resigned ?? isAdmin) ? resigned.reason : null,
    end_date: resigned ? resigned.end_date : null
  };
});

    return (
        <Loader data={dataWithReasons.length}>
    <div className="home">
      <div className="homeContainer">
        <div className="tables">
          {dataWithReasons.length !== 0 ? (
          <Datatable data={dataWithReasons} link={"/humanresourses/resignedemployees"} title={"Resigned employees list"}/>
               ):
              (<div>Waiting for loading</div>
              )
          }
        </div>
      </div>
    </div>
            </Loader>
  );
};

export default Resigned;