import Datatable from "../../components/datatable/Datatable";
import {useEffect, } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { API } from "../../api/api";
import { useFetchData } from "../../contexts/DataContext";

import Loader from "../../components/loader/Loader";
const Salaries = () => {

const { user } = useAuth();
  const {
    fetchData: getEmployees,
    data: employees,
  } = useFetchData(API.EMPLOYEES.projects.list, []);

  useEffect(() => {
    getEmployees();
  }, []);

const {
        fetchData: getSalaries,
        data: salaries,
    } = useFetchData(API.SALARIES.list, []);

    useEffect(() => {
        getSalaries();
    }, []);

    const dataWithSalaries = employees.map(employee => {
        const salary = salaries.find(salary => salary.employee === employee.employee_id);
        let ratioString;
        if (salary && salary.ratio < 75){
            ratioString = "😭";
        }
        else if (salary && (salary.ratio >= 75 && salary.ratio < 90)){
            ratioString = "😢";
        }
        else if (salary && (salary.ratio >= 90 && salary.ratio < 100)){
            ratioString = "🙂";
        }
        else {
            ratioString = "😍"
        }
        return {
            id: employee.id,
            name: employee.name,
            surname: employee.surname,
            projects: employee.projects.map((project) => {
                return project.is_main ? `${project.name} (Main)` : `${project.name}`;}).join(", "),
            department: employee.department,
            third_name: employee.third_name,
            salary: salary ? salary.salary : null,
            ratio: salary ? `${salary.ratio} % ${ratioString}` : null,
            market_salary: salary ? salary.market_salary : null
        };
    });

    return (
        <Loader data={dataWithSalaries.length}>
            <div className="home">
                <div className="homeContainer">
                    <div className="tables">
                    {dataWithSalaries.length !==0 ? (
                            <Datatable data={dataWithSalaries} link={"/compensations/salary"} title={"Employees salary list"}/>
                        ):
                        (<div>Waiting for loading</div>
                        )
                    }
                    </div>
                </div>
            </div>
        </Loader>
    );
};
export default Salaries;
