import "./navbar.scss";
import Image from '../../images/logo.png';
import { Nav } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";

const Navbar = () => {

const { user, login, logout } = useAuth();

  return (
    <div className="navbar">
        <div className="wrapper">
            <div className="wrapper__logo">
                <img src={Image} alt={"logo"}/>
            </div>
            <div className="items">
                {!user ? (
                    <Nav.Link onClick={login}>Login</Nav.Link>
                ) : (<>
                    <div className="item"> {user.first_name} </div>
                    <Nav.Link className="item" onClick={logout}>Logout</Nav.Link>
                </>
            ) }
            </div>
        </div>
    </div>
  );
};

export default Navbar;
