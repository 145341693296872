import "./datatable.scss";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { userColumns, filterDataByFields } from "./dataPrettier";
import { useAuth } from "../../contexts/AuthContext";
import { useState, useEffect } from "react";

const Datatable = (data) => {
  const { user } = useAuth();
  const isAdmin = user.groups.some((group) => (group.name === 'Admin' || group.name === "HR"));

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      flex: 1,
      justifyContent: "center",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <a
              href={`/admin${data.link}/${params.row.id}/change/`}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="adminViewButton">Edit</div>
            </a>
          </div>
        );
      },
    },
  ];

  let columns = Object.keys(data.data[0]).map((key) => key);
  let pretty_columns = filterDataByFields(userColumns, columns);

  const rowsPerPageOptions = [100, 50, 25, 12];
  const minPageSize = Math.min(...rowsPerPageOptions);

  const [pageSize, setPageSize] = useState(minPageSize);

  useEffect(() => {
    setPageSize(minPageSize);
  }, [minPageSize]);

  return (
    <div className="datatable">
      <div className="datatable-text">
        {data.title !== undefined ? String(data.title).toUpperCase() : ""}
      </div>
      <DataGrid
        className="datagrid"
        rows={data.data}
        columns={isAdmin ? pretty_columns.concat(actionColumn) : pretty_columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={rowsPerPageOptions}
        sx={{
          boxShadow: "6px 20px 10px 1px rgba(201, 201, 201, 0.47)",
          borderRadius: "25px",
          fontSize: "0.95rem",
        }}
        slots={{ toolbar: GridToolbar }}
      />
    </div>
  );
};

export default Datatable;
