import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const Tableau10 = [
  '#4e79a7',
  '#f28e2c',
  '#e15759',
  '#76b7b2',
  '#59a14f',
  '#edc949',
  '#af7aa1',
  '#ff9da7',
  '#9c755f',
  '#bab0ab',
];

export default function Bar({xDataKey, xLabel, width, yLabel, data}) {
const [color, setColor] = React.useState('#4e79a7');

const handleChange = (event, nextColor) => {
    setColor(nextColor);
  };
const createDataKeys = (data) => {
  const keys = Object.keys(data[0]);

  const filteredKeys = keys.filter(key => key !== xDataKey);

  return filteredKeys.map(key => ({
    dataKey: key,
    label: String(key).toUpperCase().replace("_", " "),
    color: color,
  }));
};



  return (
<Stack direction="column" spacing={2} alignItems="center" sx={{ width: '100%' }}>
    <BarChart
        dataset={data}
        xAxis={[{label: xLabel, scaleType: 'band', dataKey: xDataKey
        }]}

        series={createDataKeys(data)}
        yAxis={[{label: yLabel}]}
        width={width !== undefined ? width : 500}
        height={300}
        margin={{ left: 80 }}
        sx={
            {
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: 'translate(-20px, 0)',
                },
            }
        }
    />
    <ToggleButtonGroup
        value={0}
        exclusive
        onChange={handleChange}
    >
        {Tableau10.map((value) => (
            <ToggleButton key={value} value={value} sx={{ p: 1 }}>
                <div
                    style={{
                        width: 15,
                        height: 15,
                        backgroundColor: value,
                        display: 'inline-block',
                    }}
                />
            </ToggleButton>
        ))}
    </ToggleButtonGroup>
</Stack>
  );
}