import Datatable from "../../components/datatable/Datatable";
import {useEffect, } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { API } from "../../api/api";
import { useFetchData } from "../../contexts/DataContext";

import Loader from "../../components/loader/Loader";
import "./meetings.scss";
const Meetings = () => {
  const { user } = useAuth();
  const {
    fetchData: getQMeetings,
    data: qMeetings,
  } = useFetchData(API.EMPLOYEES.qmeetings.list, []);

  useEffect(() => {
    getQMeetings();
  }, []);

    const {
    fetchData: getHRMeetings,
    data: hrMeetings,
  } = useFetchData(API.EMPLOYEES.hrmeetings.list, []);

  useEffect(() => {
    getHRMeetings();
  }, []);
       return (
           <Loader data={qMeetings.length}>
           <div className="meetings">
              <div className="tables">
                {qMeetings.length ? (<Datatable data={qMeetings}
                                                                link={"/humanresourses/quantummeeting"}
                                                                title={"Quantum meetings list"}/>):
                    (<div>Waiting for loading</div>
                    )}
                {hrMeetings.length ? (<Datatable data={hrMeetings}
                                                                link={"/humanresourses/hrmeeting"}
                                                                title={"HR meetings list"}/>):
            (<div>Waiting for loading</div>
            )}
              </div>
           </div>
    </Loader>
       );
}
export default Meetings;