import {useState, useEffect} from "react";
import {Audio} from "react-loader-spinner";

const Loader = ({data, children}) => {
    const [waitOver, setWaitOver] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setWaitOver(true);
        }, 500);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    return (
        waitOver && data !== 0 ? children :
            <Audio
                height="200"
                width="200"
                radius="9"
                color="rgb(160, 160, 160)"
                ariaLabel="three-dots-loading"
                wrapperStyle={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}
            />
    );
};

export default Loader;