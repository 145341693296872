import {useEffect, useState,} from "react";
import { useAuth } from "../../contexts/AuthContext";
import { API } from "../../api/api";
import { useFetchData } from "../../contexts/DataContext";
import GroupsWidget from "../../components/widgets/GroupsWidget";

import Loader from "../../components/loader/Loader";
import "./employeeGroups.scss";
const EmployeeGroups = () => {
    const [showDepartments, setShowDepartments] = useState(false);
    const [showGrades, setShowGrades] = useState(false);
    const [showOffices, setShowOffices] =  useState(false);
    const [showCountries, setShowCountries] =  useState(false);
    const {
        fetchData: getGrades,
        data: grades,
    } = useFetchData(API.EMPLOYEES.stats.grades.list, []);

    useEffect(() => {
        getGrades();
    }, []);

    const {
        fetchData: getDepartments,
        data: departments,
    } = useFetchData(API.DEPARTMENTS.list, []);

    useEffect(() => {
        getDepartments();
    }, []);

    const {
        fetchData: getOffices,
        data: offices,
    } = useFetchData(API.OFFICES.list, []);

    useEffect(() => {
        getOffices();
    }, []);

    const {
        fetchData: getCountries,
        data: countries,
    } = useFetchData(API.COUNTRIES.list, []);

    useEffect(() => {
        getCountries();
    }, []);

    const handleShowGrades = () => {
        setShowGrades(!showGrades);
    }
    const handleShowDepartment = () => {
        setShowDepartments(!showDepartments);
    }
    const handleShowOffices = () => {
        setShowOffices(!showOffices);
    }
    const handleShowCountries = () => {
        setShowCountries(!showCountries);
    }
    return (
        <Loader data={countries.length}>
    <div className="home">
        <div className="homeContainer">
            <div className="groups">
                <div className="group">
                    <div className="group-header">
                        <div className="group-title">Employees per Grade</div>
                        <div className="show-group" onClick={handleShowGrades}>{showGrades ? "Close" : "Show"}</div>
                    </div>
                    {showGrades && (
                        <div className="widgets">
                            {grades.map(grade => (
                                <GroupsWidget key={grade.id}
                                              data={{
                                                  title: grade.grade,
                                                  result: grade.grade_count
                                              }}
                                              type={"grade"}/>
                            ))}
                        </div>)
                    }
                </div>
                <div className="group">
                    <div className="group-header">
                        <div className="group-title">Employees per Department</div>
                        <div className="show-group"
                             onClick={handleShowDepartment}>{showDepartments ? "Close" : "Show"}</div>
                    </div>
                    {showDepartments && (
                        <div className="widgets">
                            {departments.map(department => (
                                <GroupsWidget key={department.id}
                                              data={{
                                                  title: department.name,
                                                  result: department.num_employees
                                              }}
                                              type={"department"}/>
                            ))}
                        </div>)
                    }
                </div>
                <div className="group">
                    <div className="group-header">
                        <div className="group-title">Employees per Offices</div>
                        <div className="show-group" onClick={handleShowOffices}>{showOffices ? "Close" : "Show"}</div>
                    </div>
                    {showOffices && (
                        <div className="widgets">
                            {offices.map(office => (
                                <GroupsWidget key={office.id}
                                              data={{
                                                  title: office.name,
                                                  result: office.num_employees
                                              }}
                                              type={"office"}/>
                            ))}
                        </div>)
                    }
                </div>
                <div className="group">
                    <div className="group-header">
                        <div className="group-title">Employees per Countries</div>
                        <div className="show-group" onClick={handleShowCountries}>{showCountries ? "Close" : "Show"}</div>
                    </div>
                    {showCountries && true && (
                        <div className="widgets">
                            {countries.map(country => (
                                <GroupsWidget key={country.id}
                                              data={{
                                                  title: country.name,
                                                  result: country.num_employees
                                              }}
                                              type={"country"}/>
                            ))}
                        </div>)
                    }
                </div>
            </div>
        </div>
    </div>
            </Loader>
    );
};

export default EmployeeGroups;