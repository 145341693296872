import {Outlet} from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import "./layaout.scss";
import {useAuth} from "../../contexts/AuthContext";

const AppLayout = () => {

    const {user} = useAuth();
    const groupsLength = user ? user.groups.length : 0;
    return <div>
        <Navbar/>
        <div style={{padding: '75px 0px 0px 75px'}}>
            {!user ? (<div className="warning-not-login">PLEASE LOGIN TO SEE CONTENT</div>)
                :
                (
                    groupsLength === 0 ? (<>
                        <div className="warning-not-login">IT LOOKS LIKE YOU HAVE NO PERMISSIONS,
                            PLEASE CONNECT TO ADMINISTRATOR
                        </div>
                    </>) : (<>
                            <Sidebar/>
                            <Outlet/>
                        </>
                    ))
            }
        </div>
    </div>
        ;
};

export default AppLayout;