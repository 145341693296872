import Datatable from "../../components/datatable/Datatable";
import {useEffect, } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { API } from "../../api/api";
import { useFetchData } from "../../contexts/DataContext";
import Loader from "../../components/loader/Loader";
const Contractors = () => {
  const { user } = useAuth();
  const {
    fetchData: getContractors,
    data: contractors,
  } = useFetchData(API.CONTRACTORS.list, []);

  useEffect(() => {
    getContractors();
  }, []);

  return (
      <Loader data={contractors.length}>
    <div className="home">
      <div className="homeContainer">
        <div className="tables">
        {contractors.length ? (<Datatable data={contractors} link={"/humanresourses/contractor"} title={"Contractors List"}/>):
            (<div>Waiting for loading</div>
            )}
        </div>
      </div>
    </div>
      </Loader>
  );
};

export default Contractors;