import "./widget.scss";
import SchoolIcon from '@mui/icons-material/School';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PublicIcon from '@mui/icons-material/Public';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

const GroupsWidget = ({data, type}) => {
    let icon_type;
    switch (type){
        case "grade":
            icon_type = <SchoolIcon className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.5)",
              color: "black",
            }}/>;
            break;
        case "department":
            icon_type = <GroupsIcon className="icon"
            style={{
              backgroundColor: "rgba(57,218,32, 0.5)",
              color: "black",
            }}/>;
            break;
        case "office":
            icon_type = <ApartmentIcon className="icon"
            style={{
              backgroundColor: "rgba(218,32,181, 0.5)",
              color: "black",
            }}/>;
            break
        case "country":
            icon_type = <PublicIcon className="icon"
            style={{
              backgroundColor: "rgba(32,184,218, 0.5)",
              color: "black",
            }}
            />;
            break;
        case "people":
            icon_type = <PersonOutlinedIcon className="icon"
            style={{
              backgroundColor: "rgba(166,25,48, 0.5)",
              color: "black",
            }}
            />;
            break;
        case "money":
            icon_type = <LocalAtmIcon className="icon"
            style={{
              backgroundColor: "rgba(202,183,68,0.5)",
              color: "black",
            }}
            />;
            break;
        default: break;
    }
    data["icon"] = icon_type
    return (
        <div className="widget">
            <div className="left">
                <span className="title">{String(data.title).toUpperCase()}</span>
                <span className="counter">
                    {data.result}
                </span>
                <span className="link"></span>
                {data.percent !== undefined &&
                    (<span className="title"> Percent: {data.percent}%</span>)}
            </div>
            <div className="right">
                {data.icon}
            </div>
        </div>
    );
}
export default GroupsWidget;