import Bar from "../../components/charts/Bar";
import Pie from "../../components/charts/Pie";
import { Audio } from 'react-loader-spinner';
import GroupsWidget from "../../components/widgets/GroupsWidget";
import "./analytics.scss"
import {useEffect} from "react";
import {useFetchData} from "../../contexts/DataContext";
import Loader from "../../components/loader/Loader";
import { API } from "../../api/api";


const Analytics = () => {

const {
        fetchData: getAge,
        data: age,
    } = useFetchData(API.EMPLOYEES.stats.age.list, []);

    useEffect(() => {
        getAge();
     }, []);
    const dataAge = {
        title : "Average Age",
        result : age.average_age + " y."
    }
const {
        fetchData: getResignedDuration,
        data: resignedDuration,
    } = useFetchData(API.RESIGNED.duration.base.list, []);

    useEffect(() => {
        getResignedDuration();
     }, []);
    const dataResignedDuration = {
        title : "Average duration (R)",
        result : resignedDuration.duration + " y."
    }
    const {
        fetchData: getEmployeeDuration,
        data: employeeDuration,
    } = useFetchData(API.EMPLOYEES.stats.duration.base.list, []);

    useEffect(() => {
        getEmployeeDuration();
     }, []);
    const dataEmployeeDuration = {
        title : "Average duration (E)",
        result : employeeDuration.duration + " y."
    }

    const {
        fetchData: getGrades,
        data: grades,
    } = useFetchData(API.EMPLOYEES.stats.grades.list, []);

    useEffect(() => {
        getGrades();
     }, []);
    const gradesPie = grades !== undefined ? (grades.map(grade => ({
        value: grade.grade_count,
        label: grade.grade
    }))) : null;

        const {
        fetchData: getDepartments,
        data: departments,
    } = useFetchData(API.DEPARTMENTS.list, []);

    useEffect(() => {
        getDepartments();
    }, []);

    const departmentsPie = departments !== undefined ? (departments.map(department => ({
        value: department.num_employees,
        label: department.name
    }))) : null;

    const {
        fetchData: getAverageSalary,
        data: averageSalary,
    } = useFetchData(API.SALARIES.AVERAGE.all, []);

    useEffect(() => {
        getAverageSalary();
    }, []);
    const dataSalary = {
        title : "Average salary",
        result : averageSalary.salary
    }
    const dataMedian = {
        title : "Median of salary",
        result : averageSalary.median
    }
    const dataMedianRatio = {
        title : "Company / Market median ratio ",
        result : averageSalary.median_to_market + "%"
    }

    const {
    fetchData: getEmployeeDurationStats,
        data: employeeDurationStats,
    } = useFetchData(API.EMPLOYEES.stats.duration.stats.list, []);

    useEffect(() => {
        getEmployeeDurationStats();
    }, []);

    const {
    fetchData: getResignedEmployeeDurationStats,
        data: resignedEmployeeDurationStats,
    } = useFetchData(API.RESIGNED.duration.stats.list, []);

    useEffect(() => {
        getResignedEmployeeDurationStats();
    }, []);

    const {
    fetchData: getEmployeeSeasonsHiringStats,
        data: employeeSeasonsHiringStats,
    } = useFetchData(API.EMPLOYEES.stats.seasons.list, []);

    useEffect(() => {
        getEmployeeSeasonsHiringStats();
    }, []);

    const {
    fetchData: getResignedEmployeeSeasonsStats,
        data: resignedEmployeeSeasonsStats,
    } = useFetchData(API.RESIGNED.seasons.list, []);

    useEffect(() => {
        getResignedEmployeeSeasonsStats();
    }, []);

const {
    fetchData: getResignedEmployeeReasonsStats,
        data: resignedEmployeeReasonsStats,
    } = useFetchData(API.RESIGNED.reasons.list, []);

    useEffect(() => {
        getResignedEmployeeReasonsStats();
    }, []);

const {
    fetchData: getProjectSalaryStats,
        data: projectSalaryStats,
    } = useFetchData(API.SALARIES.AVERAGE.projects, []);

    useEffect(() => {
        getProjectSalaryStats();
    }, []);

const {
    fetchData: getDepartmentSalaryStats,
        data: departmentSalaryStats,
    } = useFetchData(API.SALARIES.AVERAGE.departments, []);

    useEffect(() => {
        getDepartmentSalaryStats();
    }, []);

  return (
      <Loader data={departmentSalaryStats.length}>
    <div className="home">
      <div className="homeContainer">
          <div className="groups">
              <div className="group">
                  <div className="group-header">
                      <div className="group-title">Average age & Duration Employees work</div>
                  </div>
                  <div className="widgets">
                      {age.average_age !== undefined && (<GroupsWidget type="people" data={dataAge}/>)}
                      {employeeDuration.duration !== undefined &&
                          (<GroupsWidget type="people" data={dataEmployeeDuration}/>)}
                      {resignedDuration.duration !== undefined &&
                          (<GroupsWidget type="people" data={dataResignedDuration}/>)}
                  </div>
                  <div className="chartsContainer">
                      <div className="chart">
                          <div className="chart-title">Employees Duration of work statistic</div>
                          <div className="charts">
                              {employeeDurationStats.length && (
                                  <Bar xDataKey={"duration"} data={employeeDurationStats}
                                       xLabel={"Years"} yLabel={"Number of Employees"}
                                  />)}
                          </div>
                      </div>
                      <div className="chart">
                          <div className="chart-title">Resigned Employees Duration of work statistic</div>
                          <div className="charts">
                              {resignedEmployeeDurationStats.length && (
                                  <Bar xDataKey={"duration"} data={resignedEmployeeDurationStats}
                                       xLabel={"Years"} yLabel={"Number of Resigned"}
                                  />)}
                          </div>
                      </div>
                      <div className="chart">
                          <div className="chart-title">Employees Seasons Hiring (Per quarters) statistic</div>
                          <div className="charts">
                              {employeeSeasonsHiringStats.length && (
                                  <Bar xDataKey={"quarter"} data={employeeSeasonsHiringStats}
                                       xLabel={"Quarter of year"} yLabel={"Number of Hired"}
                                  />)}
                          </div>
                      </div>
                      <div className="chart">
                          <div className="chart-title">Employees Seasons Resigning (Per quarters) statistic</div>
                          <div className="charts">
                              {resignedEmployeeSeasonsStats.length && (
                                  <Bar xDataKey={"quarter"} data={resignedEmployeeSeasonsStats}
                                       xLabel={"Quarter of year"} yLabel={"Number of Resigned"}
                                  />)}
                          </div>
                      </div>
                  </div>
                      <div className="group">
                  <div className="group-header">
                      <div className="group-title">Resigned Employees per Resigning Reasons</div>
                  </div>
                      <div className="widgets">
                          {resignedEmployeeReasonsStats.map(reason => (
                              <GroupsWidget key={reason.reason}
                                            data={{
                                                title: reason.reason,
                                                result: reason.count
                                            }}
                                            type={"people"}/>
                          ))}
                      </div>
                  </div>
              </div>
              <div className="group">
                  <div className="group-header">
                      <div className="group-title">Employees per Grades</div>
                  </div>
                  {grades !== undefined && (
                      <div className="widgets">
                          {grades.map(grade => (
                              <GroupsWidget key={grade.id}
                                            data={{
                                                title: grade.grade,
                                                result: grade.grade_count,
                                                    percent: grade.grade_percent
                                                }}
                                                type={"grade"}/>
                              ))}
                          </div>)
                      }
                  <div className="chartsContainer">
                      <div className="chart">
                          <div className="chart-title">All employees compared by grade</div>
                          <div className="charts">
                              {gradesPie !== null && (<Pie values={gradesPie}/>)}
                          </div>
                      </div>
                  </div>
                </div>
              <div className="group">
                  <div className="group-header">
                      <div className="group-title">Employees per Department</div>
                  </div>
                      {departments !== undefined && (
                          <div className="widgets">
                              {departments.map(department => (
                                  <GroupsWidget key={department.id}
                                                data={{
                                                    title: department.name,
                                                    result: department.num_employees,
                                                    percent: department.percent
                                                }}
                                                type={"department"}/>
                              ))}
                          </div>)
                      }
                      <div className="chartsContainer">
                          <div className="chart">
                              <div className="chart-title">All employees compared by departments</div>
                              <div className="charts">
                                  {departmentsPie !== null && (<Pie values={departmentsPie}/>)}
                              </div>
                          </div>
                      </div>
              </div>
              <div className="group">
                  <div className="group-header">
                      <div className="group-title">Salary statistics</div>
                  </div>
                      <div className="widgets">
                          {averageSalary.salary !== undefined && (<GroupsWidget type="money" data={dataSalary}/>)}
                          {averageSalary.median !== undefined && (<GroupsWidget type="money" data={dataMedian}/>)}
                          {averageSalary.median_to_market !== undefined && (
                              <GroupsWidget type="money" data={dataMedianRatio}/>)}
                      </div>
                  <div className="chartsContainer">
                      <div className="chart">
                          <div className="chart-title">Employees Salary per Project statistic</div>
                          <div className="charts">
                              {projectSalaryStats.length && (
                                  <Bar xDataKey={"name"} data={projectSalaryStats}
                                       xLabel={"Projects"} yLabel={"Average Salary"}
                                  />)}
                          </div>
                      </div>
                      <div className="chart">
                          <div className="chart-title">Employees Salary per Department statistic</div>
                          <div className="charts">
                              {departmentSalaryStats.length && (
                                  <Bar xDataKey={"name"} data={departmentSalaryStats}
                                       xLabel={"Departments"} yLabel={"Average Salary"}
                                  />)}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
      </Loader>
  );
};

export default Analytics;